import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { homePage } }, location }) {

	return (
		<>
			<Seo title="Homepage" />
			{ homePage && homePage.content &&
				<DynamicZone components={ homePage.content } />
			}
		</>
	)
}

export const query = graphql`
	query homePage {
		strapi {
			homePage {
				content {
					__typename
					... on StrapiQuery_ComponentHomePageContactForm {
						id
						message
						contactTitle
					}
					... on StrapiQuery_ComponentHomePageProductGrid {
						id
						message
						productTitle
					}
					... on StrapiQuery_ComponentHomePageHero {
						id
						image {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData(
										width: 1920
										quality: 80
										formats: [AUTO, WEBP]
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomePageAboutUs {
						id
						aboutTitle
						content
						aboutAccordion {
							id
							accordionContent
							accordionHeading
						}
					}
					... on StrapiQuery_ComponentHomePageWelcome {
						id
						heading
						text
					}
				}
			}
		}
	}
`