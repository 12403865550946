import React, { useEffect, useState } from 'react'

export default function AccordionItem( { data, openOnLoad } ) {

	const [ active, setActive ] = useState()

	useEffect(() => {
		if ( openOnLoad ) {
			setActive( true )
		}
	}, [ openOnLoad ])

	if ( data.props.hideItem ) {
		return null
	}

	return (
		<div className={`accordion__item ${ active ? 'active' : '' }`}>
			<button
				className="accordion__selector"
				onClick={ () => setActive(!active) }
			>
				{ data.props.accordionTitle }

				<div className={`accordion__selector-icon-container ${ active ? 'active' : '' }`}>
					<div className="accordion__selector-icon accordion__selector-icon--vertical"></div>
					<div className="accordion__selector-icon accordion__selector-icon--horizontal"></div>
				</div>

			</button>
			<div className={`accordion__content-container ${ active ? 'active' : '' }`}>
				<div className="accordion__content">
					{ data.props.children }
				</div>
			</div>
		</div>
	)
}