import React, { useState } from "react";
import PropTypes from 'prop-types';

import ProductCard from '../ProductCard/ProductCard'

export default function ProductGrid( props ) {

	const { products, categories } = props

	const [ selectedCategory, setSelectedCategory ] = useState(null);

	const filterProductsByCategory = product => {
		if (!selectedCategory) {
			// If no category is selected, show all products
			return true
		}
		// Filter products by the selected category
		return product.category.id === selectedCategory
	}

	const moveSoldToBottom = ( a, b ) => {
		// Items with quantityInStock: 0 should come after other items
		if (a.quantityInStock === 0 && b.quantityInStock !== 0) {
			return 1 // Move 'a' to the bottom
		}
		if (a.quantityInStock !== 0 && b.quantityInStock === 0) {
			return -1 // Move 'b' to the bottom
		}
		// For items with the same quantityInStock, maintain their original order
		return 0
	}

	return (
		<div className="product-grid">
			<div className="product-grid__categories">
				<span className="product-grid__categories-label">Filter by:</span>
				<ul className="product-grid__categories-list">
					<li className={`product-grid__categories-item ${ !selectedCategory ? 'active' : '' }`}>
						<button className="product-grid__categories-button" onClick={() => setSelectedCategory( null ) }>All Products</button>
					</li>
					{ categories.map( category => (
						<li
							className={`product-grid__categories-item ${ selectedCategory === category.id ? 'active' : '' }`}
							key={ category.id }
						>
							<button className="product-grid__categories-button" onClick={() => setSelectedCategory( category.id ) }>{ category.name }</button>
						</li>
					) ) }
				</ul>
			</div>
			<div className="product-grid__wrapper">
				{ products.filter(filterProductsByCategory).sort( moveSoldToBottom ).map( product => (
					<React.Fragment key={ product.id }>
						<ProductCard product={ product } showCartButton={ true } />
					</React.Fragment>
				) ) }
			</div>
		</div>
	)
}

ProductGrid.propTypes = {
	products: PropTypes.array
}