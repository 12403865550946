import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Anchor from '../../Anchor/Anchor'
import ProductGrid from '../../ProductGrid/ProductGrid'
import Heading from '../../Heading/Heading'

export default function ProductsZone( props ) {

	const query = useStaticQuery( graphql`
		query productQuery {
			strapi {
				products(sort: "category.categorySort:ASC") {
					id
					name
					price
					description
					quantityInStock
					slug
					medium
					dimensions
					category {
						id
						categorySort
						name
					}
					images {
						id
						url
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 250)
							}
						}
					}
				}
				categories(sort: "categorySort:ASC") {
					id
					name
				}
			}
		}
	`)

	const { products, categories } = query.strapi
	const data = props.input

	return (
		<section className="products-zone">
			<Anchor anchorId="shop" />
			<Heading heading={ data.productTitle } subHeading={ true } />
			<div className="products-zone__wrapper wrapper">
				<ProductGrid products={ products } categories={ categories } />
			</div>
		</section>
	)
}

ProductsZone.propTypes = {
	input: PropTypes.object.isRequired
}
