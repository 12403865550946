import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import MailchimpModal from '../../Mailchimp/MailchimpModal'

import SecondaryLogo from '../../../images/svgs/arcipluvia-logo-secondary.svg'

export default function Welcome( props ) {

	const data = props.input
	// const [ toggleModal, setToggleModal ] = useState( false )

	return (
		<section className="welcome">
			<div className="welcome__wrapper wrapper">
				<div className="welcome__text-container">
					{ data.heading &&
						<h1 className="welcome__heading">
							{ data.heading }
						</h1>
					}
					{ data.text &&
						<p className="welcome__description">
							{ data.text }
						</p>
					}
					{/* <button className="button" onClick={ () => setToggleModal( true ) }>Subscribe to Newsletter</button> */}
				</div>
				<div className="welcome__image-container">
					<SecondaryLogo className="welcome__image" />
				</div>
			</div>

			{/* <MailchimpModal toggleModal={ toggleModal } setToggleModal={ setToggleModal } /> */}
		</section>
	)
}

Welcome.propTypes = {
	input: PropTypes.object.isRequired
};