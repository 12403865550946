import React from 'react'

import ProductsZone from './DynamicZoneComponents/ProductsZone'
import HeroBanner from './DynamicZoneComponents/HeroBanner'
import Contact from './DynamicZoneComponents/Contact'
import AboutUs from './DynamicZoneComponents/AboutUs'
import Welcome from './DynamicZoneComponents/Welcome'

export default function DynamicZone( { components } ) {

	return (
		<>
			{ components.map(( comp, index ) => {
				switch (comp.__typename) {
					case 'StrapiQuery_ComponentHomePageContactForm':
						return <Contact key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomePageProductGrid':
						return <ProductsZone key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomePageHero':
						return <HeroBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomePageAboutUs':
						return <AboutUs key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomePageWelcome':
						return <Welcome key={ index } input={ comp } />
					default:
						return null
				}
			}) }
		</>
	)
}