import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroBanner = ({input}) => {
	const data = input

	return (
		<section className="hero-banner">
			<div className="hero-banner__wrapper">
				<GatsbyImage
					image={data.image.imageFile.childImageSharp.gatsbyImageData}
					layout="fullWidth"
					loading="eager"
					className="hero-banner__image"
					alt=""
				/>
			</div>
		</section>
	)
}

HeroBanner.propTypes = {
	input: PropTypes.shape({
		image: PropTypes.shape({
			imageFile: PropTypes.object.isRequired,
		}).isRequired,
	}).isRequired,
};

export default HeroBanner;
