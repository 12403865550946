import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import Anchor from '../../Anchor/Anchor'
import Heading from '../../Heading/Heading'
import Accordion from '../../Accordion/Accordion'
export default function AboutUs( props ) {

	const data = props.input

	return (
		<section className="about-us">
			<Anchor anchorId="about" />
			<Heading heading={ data.aboutTitle } subHeading={ true } />
			<div className="about-us__wrapper wrapper">
				<ReactMarkdown children={ data.content } className="about-us__description" />
				{ !!data.aboutAccordion.length &&
					<div className="about-us__accordion">
						<Accordion>
							{ data.aboutAccordion.map( item => (
								<div accordionTitle={ item.accordionHeading } key={ item.id }>
									<ReactMarkdown children={ item.accordionContent } className="about-us__description" />
								</div>
							) ) }
						</Accordion>
					</div>
				}
			</div>
		</section>
	)
}

AboutUs.propTypes = {
	input: PropTypes.object.isRequired
};